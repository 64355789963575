<template>
  <v-dialog
    persistent
    scrollable
    max-width="500px"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <app-title>{{ sessionTitle }}</app-title>
        <v-btn @click="handleDeleteSession()" text icon color="error">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="origin == 'booking'">
        Agendado pelo App
      </v-card-subtitle>
      <v-card-text>
        <template v-if="isSessionType">
          <v-row class="mt-1">
            <v-col class="align-center">
              <app-icon class="mr-4">person</app-icon>

              <a
                @click="handleEditCustomer()"
                class="font-weight-bold secondary--text"
              >
                {{ customerName }}
                <app-icon v-if="hasCustomer" small class="primary--text">
                  arrow_outward
                </app-icon>
                <v-chip v-if="!hasCustomer" color="error" x-small>
                  Não cadastrado
                </v-chip>
              </a>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row class="mt-1">
            <v-col class="align-center">
              <app-icon class="mr-4">account_circle</app-icon>
              <a class="font-weight-bold secondary--text">
                {{ session.title }}
              </a>
            </v-col>
          </v-row>
        </template>

        <v-row v-if="isClinic">
          <v-col class="align-center">
            <app-icon class="mr-4">badge</app-icon>
            <span class="font-weight-bold secondary--text">
              {{ session.professional.name }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="session.office">
          <v-col class="align-center">
            <app-icon class="mr-4">meeting_room</app-icon>
            <span class="font-weight-bold secondary--text">
              {{ session.office.name }}
            </span>
            <OfficeSearch
              ref="OfficeSearch"
              class="d-none"
              v-model="session.office"
              @input="updateSession()"
            />
            <v-btn
              class="ml-2"
              icon
              small
              color="primary"
              @click="handleEditOffice"
            >
              <app-icon small>mdi-pencil</app-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="align-start d-flex">
            <app-icon class="mr-4">event</app-icon>
            <div>
              <span class="font-weight-bold secondary--text">
                {{ $format.dateBr(session.start_at) }}
              </span>
              <app-icon class="ml-4 mr-4">schedule</app-icon>
              <span class="font-weight-bold secondary--text">
                {{ $format.time(session.start_at_time) }}
              </span>
              <SessionNotificationStatus
                v-if="isSessionType"
                class="mt-1"
                :session="session"
              />
              <div
                class="mt-2"
                v-if="
                  (notShowed && $acl.can('can_manage_own_calendar')) ||
                  $acl.can('can_manage_all_calendar')
                "
              >
                <v-btn
                  class="mr-4"
                  small
                  outlined
                  color="primary"
                  @click="handleRescheduleSession()"
                >
                  Remarcar este
                </v-btn>
                <v-btn
                  v-if="session.session_group.has_repetitions"
                  small
                  outlined
                  color="primary"
                  @click="handleRescheduleSessionGroup()"
                >
                  Remarcar todos
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="session.session_group.repetitions_count > 1">
          <v-col class="align-start">
            <v-icon class="mr-4">mdi-calendar-blank-multiple</v-icon>
            <span class="font-weight-bold secondary--text">
              Pacote de Sessões (sessão {{ session.number }} de
              {{ session.session_group.repetitions_count }})
            </span>
            <v-chip class="ml-2" x-small filter>
              {{ frequency[session.session_group.frequency].text }}
            </v-chip>
          </v-col>
        </v-row>
        <template v-if="isSessionType">
          <template v-if="session.is_online_session">
            <v-row class="mt-1">
              <v-col class="align-center">
                <app-icon class="mr-4">mdi-video</app-icon>
                <a
                  class="font-weight-bold secondary--text"
                  @click.stop="openVideoCall()"
                >
                  <template v-if="session.video_call_link">
                    {{ session.video_call_link }}
                  </template>
                  <template v-else> Atendimento Online </template>
                  <v-btn
                    icon
                    small
                    color="primary"
                    @click.stop="handleEditVideoCallLink()"
                  >
                    <app-icon small>mdi-pencil</app-icon>
                  </v-btn>
                </a>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row class="mt-1">
              <v-col class="align-center">
                <app-icon class="mr-4">mdi-account</app-icon>
                Presencial
                <v-btn
                  icon
                  small
                  color="primary"
                  @click.stop="handleEditVideoCallLink()"
                >
                  <app-icon small>mdi-pencil</app-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-if="transactions">
            <template v-for="(transaction, index) of transactions">
              <v-row :key="index">
                <v-col class="align-start d-flex">
                  <v-icon class="mr-4">mdi-currency-usd</v-icon>
                  <div>
                    <div class="d-flex">
                      <div>
                        <div>
                          <span class="font-weight-bold secondary--text">
                            R$ {{ $format.decimal(transaction.value) }}
                          </span>
                          <a
                            v-if="
                              $acl.canUpdateTransaction(session.professional_id)
                            "
                            class="font-weight-bold secondary--text ml-2"
                            @click="openTransactionForm(transaction.id)"
                          >
                            <app-icon small class="primary--text">
                              arrow_outward
                            </app-icon>
                          </a>
                        </div>
                        <app-transaction-status-chip
                          x-small
                          filter
                          :transaction="transaction"
                        />
                      </div>
                    </div>
                    <div
                      v-if="$acl.canUpdateTransaction(session.professional_id)"
                      class="mt-1"
                    >
                      <v-btn
                        v-if="transaction.status != 'paid'"
                        @click="openTransactionForm(transaction.id)"
                        small
                        outlined
                        color="primary"
                      >
                        Registrar pagamento
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-else>
            <v-row>
              <v-col class="align-start d-flex">
                <v-icon class="mr-4">mdi-currency-usd</v-icon>
                <div>
                  <span class="font-weight-bold secondary--text">
                    Sem registro financeiro
                  </span>
                  <div
                    v-if="$acl.canUpdateTransaction(session.professional_id)"
                    class="mt-2"
                    @click="handleAddTransaction()"
                  >
                    <v-btn outlined small color="primary">
                      Adicionar registro financeiro
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
        </template>

        <v-row>
          <v-col>
            <app-icon class="mr-4">label</app-icon>
            <span>Etiquetas</span>
            <div class="mt-2">
              <TagsSelect
                class="ml-9"
                :type="'session'"
                :value="session.tags"
                @input="storeTags($event)"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <app-icon class="mr-2">palette</app-icon>
            <ColorSelect
              v-model="session.color"
              @input="updateSession()"
              :label="null"
            />
          </v-col>
        </v-row>
        <template v-if="isSessionType">
          <v-row>
            <v-col>
              <v-chip-group
                mandatory
                v-model="session.status"
                @change="updateSession()"
              >
                <v-chip small filter :value="'pending'">Pendente</v-chip>
                <v-chip small filter :value="'missed'" active-class="secondary">
                  Faltou
                </v-chip>
                <v-chip small filter :value="'shown'" active-class="primary">
                  Compareceu
                </v-chip>
                <v-chip small filter :value="'suspended'" active-class="error">
                  Suspensa
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-row v-if="$acl.isProfessional()" class="mt-2">
            <v-col>
              <v-btn
                color="primary"
                block
                @click="handleRegisterSessionReport()"
              >
                {{ sessionReportBtnText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" rounded outlined> Voltar </v-btn>
      </v-card-actions>
    </v-card>
    <RescheduleSessionForm
      ref="RescheduleSessionForm"
      @store="handleReschedule()"
    />
    <RescheduleSessionGroupForm
      ref="RescheduleSessionGroupForm"
      @store="handleReschedule()"
    />
    <SessionReportForm
      @store="showSession(), $emit('store')"
      ref="SessionReportForm"
    />

    <TransactionForm ref="TransactionForm" @store="showSession()" />

    <DeleteSessionDialog
      ref="DeleteSessionDialog"
      :session="session"
      @delete="handleDelete()"
    />
    <VideoCallLinkForm
      ref="VideoCallLinkForm"
      :session="session"
      @store="showSession(), $emit('store')"
    />
    <VideoCallLinkSendingDialog ref="VideoCallLinkSendingDialog" />
    <CustomerFormDialog @store="setCustomer($event)" ref="CustomerFormDialog" />
  </v-dialog>
</template>

<script>
import OfficeSearch from "@/components/company/ui/OfficeSearch.vue";
import ColorSelect from "@/components/app/ui/ColorSelect.vue";
import TagsSelect from "@/components/app/forms/TagsSelect";
import DeleteSessionDialog from "./DeleteSessionDialog.vue";
import RescheduleSessionForm from "@/components/schedule/form/RescheduleSessionForm.vue";
import RescheduleSessionGroupForm from "@/components/schedule/form/RescheduleSessionGroupForm.vue";
import SessionReportForm from "@/components/schedule/form/SessionReportForm.vue";
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import ButtonSendVideoLink from "@/components/schedule/sections/ButtonSendVideoLink.vue";
import SessionNotificationStatus from "@/components/schedule/sections/SessionNotificationStatus.vue";
import SessionStatusByCustomer from "@/components/schedule/sections/SessionStatusByCustomer.vue";
import VideoCallLinkForm from "@/components/schedule/form/VideoCallLinkForm.vue";
import VideoCallLinkSendingDialog from "@/components/schedule/sections/VideoCallLinkSendingDialog.vue";
import CustomerFormDialog from "@/components/customers/forms/CustomerFormDialog.vue";

export default {
  // mixins: [SessionMixin],

  components: {
    TagsSelect,
    RescheduleSessionForm,
    RescheduleSessionGroupForm,
    SessionReportForm,
    TransactionForm,
    ButtonSendVideoLink,
    SessionNotificationStatus,
    SessionStatusByCustomer,
    DeleteSessionDialog,
    ColorSelect,
    OfficeSearch,
    TransactionForm,
    VideoCallLinkForm,
    VideoCallLinkSendingDialog,
    CustomerFormDialog,
  },

  data() {
    return {
      situation: [
        { value: "pending", text: "Pendente" },
        { value: "suspended", text: "Suspensa" },
        { value: "confirmed", text: "Confirmado" },
        { value: "confirmed_patient", text: "Confirmado pelo Paciente" },
        { value: "canceled", text: "Cancelado" },
        { value: "missed", text: "Faltou" },
      ],

      frequency: {
        weekly: { text: "Semanal" },
        biweekly: { text: "Quinzenal" },
        monthly: { text: "Mensal" },
      },

      id: null,

      session: {
        customer: {},
        session_group: {
          transactions: [],
        },
        transactions: [],
        professional: {},
        whats_app_notifications: {},
      },

      dialog: false,
    };
  },

  created() {},

  computed: {
    isClinic() {
      return this.$store.state.auth.company.type == "clinic";
    },

    isSessionType() {
      return this.session.session_type == "session";
    },

    sessionTitle() {
      if (this.session.session_type == "session") {
        return "Sessão";
      }

      return "Compromisso pessoal";
    },

    videoCallLink() {
      return process.env.BASE_URL + "/videochamada/" + this.session.uuid;
    },

    notShowed() {
      return this.session.status != "shown";
    },

    user() {
      return this.$store.state.auth.user;
    },

    transactions() {
      if (this.session.transactions.length > 0) {
        return this.session.transactions;
      }

      if (this.session.session_group.transactions.length == 1) {
        return this.session.session_group.transactions;
      }

      return null;
    },

    sessionReportBtnText() {
      return "Editar Registro da Sessão";
    },
    origin() {
      return this.session.origin;
    },
    booking() {
      return this.session.booking;
    },
    hasCustomer() {
      return this.session.customer_id != null;
    },
    customerName() {
      if (this.hasCustomer) {
        return this.session.customer.name;
      }

      if (!this.hasCustomer) {
        return this.session.booking.name;
      }
    },
  },

  methods: {
    async open(id) {
      this.id = id;

      await this.showSession(id);

      this.dialog = true;
    },

    async showSession() {
      this.$loading.start();
      await this.$http
        .$get("sessions/session/" + this.id)
        .then((response) => {
          this.session = response.session;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleRescheduleSession() {
      this.$refs.RescheduleSessionForm.open(this.session.id);
    },

    handleRescheduleSessionGroup() {
      this.$refs.RescheduleSessionGroupForm.open(
        this.session.session_group.id,
        this.session.id
      );
    },

    handleRegisterSessionReport() {
      this.$refs.SessionReportForm.open(this.session);
    },

    handleStartVideoCall() {
      this.$router.push("/videochamada/psicologo/" + this.session.uuid);
    },

    handleReschedule() {
      this.showSession();
      this.$emit("store");
    },

    openTransactionForm(id) {
      this.$refs.TransactionForm.open(id);
    },

    handleDeleteSession() {
      this.$refs.DeleteSessionDialog.open(this.session);
    },

    async storeTags(tags) {
      let params = {
        type: "session",
        taggable_id: this.session.id,
        tags: tags.map((tag) => tag.id),
      };

      await this.$http.store("tag/sync", params).then((response) => {
        this.showSession();
        this.$emit("store");
      });
    },

    handleAddTransaction() {
      this.$refs.TransactionForm.new("in", this.session);
    },

    handleEditOffice() {
      this.$refs.OfficeSearch.open();
    },

    async updateSession() {
      await this.$http
        .update("sessions/session", this.session.id, this.session)
        .then((response) => {
          this.showSession();
          this.$emit("store");
        });
    },

    openVideoCall() {
      this.$refs.VideoCallLinkSendingDialog.open(this.session);
    },

    handleDelete() {
      this.$emit("store");
      this.dialog = false;
    },
    handleEditVideoCallLink() {
      this.$refs.VideoCallLinkForm.open();
    },
    handleEditCustomer() {
      if (this.hasCustomer) {
        this.$router.push("/cliente/" + this.session.customer_id);
      }

      if (!this.hasCustomer) {
        this.$refs.CustomerFormDialog.new({
          name: this.booking.name,
          cpf: this.booking.cpf,
          birth_date: this.booking.birth_date,
          phone_wa: `+55 ${this.booking.phone_wa}`,
          email: this.booking.email,
        });
      }
    },
    setCustomer(customer) {
      this.session.customer = customer;
      this.session.customer_id = customer.id;
      this.updateSession();
    },
  },
};
</script>
<style scoped>
span {
  vertical-align: middle;
}
</style>
