<template>
  <v-dialog
    persistent
    scrollable
    width="580px"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>
        <app-title>Criar Agendamento</app-title>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="d-flex justify-center pb-4">
            <v-chip-group
              id="chipSessionType"
              :show-arrows="false"
              mandatory
              v-model="formSessionType"
            >
              <v-chip color="primary" outlined filter value="single">
                Sessão Unica
              </v-chip>
              <v-chip color="primary" outlined filter value="multiple">
                Sessões recorrentes
              </v-chip>
              <v-chip color="primary" outlined filter value="custom_event">
                Compromisso pessoal
              </v-chip>
            </v-chip-group>
          </div>

          <v-row>
            <template v-if="showSelectProfessional">
              <v-col cols="12">
                <SelectProfessional
                  outlined
                  ref="SelectProfessional"
                  v-model="form.professional"
                />
              </v-col>
            </template>
            <template v-if="hasOffices && isSessionType">
              <v-col cols="12">
                <OfficeSearch outlined v-model="form.office" />
              </v-col>
            </template>
          </v-row>

          <template v-if="!isSessionType">
            <v-row>
              <v-col cols="12">
                <app-text-field
                  v-model="form.title"
                  label="Título"
                  required
                  outlined
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col>
                <CustomerSearch
                  v-model="form.customer"
                  @input="setSessionValue()"
                  :rules="customerRules"
                  outlined
                />
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="12" md="5">
              <app-date-picker
                v-model="form.sessions[0].start_at_date"
                :rules="startAtDateRules"
                label="Data"
                required
                outlined
              />
            </v-col>
            <v-col cols="12" md="3">
              <app-time-picker
                v-model="form.sessions[0].start_at_time"
                :rules="startAtTimeRules"
                label="Inicio"
                required
                outlined
              />
            </v-col>
            <v-col cols="12" md="4" v-if="isSessionType">
              <app-number-field
                v-model="form.sessionDuration"
                label="Duração"
                required
                :precision="0"
                :reverse="false"
                suffix=" min"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4" v-if="!isSessionType">
              <app-time-picker
                v-model="form.sessions[0].end_at_time"
                label="Fim"
                required
                :precision="0"
                :reverse="false"
                :rules="startAtTimeRules"
                suffix=" min"
                outlined
              />
            </v-col>
          </v-row>
          <template v-if="form.has_repetitions">
            <v-row class="mt-1">
              <v-col>
                <v-chip-group v-model="form.frequency" mandatory>
                  <v-chip color="primary" outlined filter :value="'weekly'">
                    Semanal
                  </v-chip>
                  <v-chip color="primary" outlined filter :value="'biweekly'">
                    Quinzenal
                  </v-chip>
                  <v-chip color="primary" outlined filter :value="'monthly'">
                    Mensal
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <app-number-field
                  v-model="form.repetitions_count"
                  label="Quantidade de Repetições"
                  required
                  outlined
                  :precision="0"
                  :reverse="false"
                  @input="calcValue()"
                />
              </v-col>
            </v-row>
          </template>
          <template v-if="isSessionType">
            <v-row>
              <v-col class="pt-0">
                <v-switch
                  lass="mt-0"
                  v-model="form.is_online_session"
                  label="Atendimento online"
                  hide-details="auto"
                  :true-value="1"
                  :false-value="0"
                />
              </v-col>
            </v-row>
            <v-row v-if="form.is_online_session">
              <v-col>
                <app-text-field
                  v-model="form.video_call_link"
                  label="Link da video chamada (opcional)"
                  prepend-inner-icon="mdi-video-outline"
                  outlined
                  hint="Adicione um link do Google Meet, Zoom ou deixe em branco para usar a video chamada do Psicoplanner"
                />
              </v-col>
            </v-row>
          </template>
          <template v-if="isSessionType">
            <template v-if="form.has_transaction">
              <v-row>
                <v-col>
                  <app-number-field
                    v-model="form.value"
                    outlined
                    :label="valueLabel"
                    append-icon="mdi-currency-brl"
                  />
                </v-col>
              </v-row>
              <template v-if="form.has_repetitions">
                <v-row>
                  <v-col class="py-1">
                    <v-chip-group
                      v-model="form.transaction_type"
                      @change="calcValue()"
                      mandatory
                    >
                      <v-chip
                        color="primary"
                        outlined
                        filter
                        :value="'sessionGroup'"
                      >
                        Lançamento único
                      </v-chip>
                      <v-chip
                        color="primary"
                        outlined
                        filter
                        :value="'session'"
                      >
                        Lançamento por sessão
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </template>
            </template>

            <v-row>
              <v-switch
                v-model="form.has_transaction"
                size="x-small"
                class="ml-4 mt-0 pt-5"
                :true-value="false"
                :false-value="true"
                label="Não lançar financeiro"
              />
            </v-row>
          </template>

          <v-row>
            <v-col>
              <ColorSelect outlined dense v-model="form.color" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <TagsSelect v-model="form.tags" type="session" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OfficeSearch from "@/components/company/ui/OfficeSearch.vue";
import ColorSelect from "@/components/app/ui/ColorSelect.vue";
import TagsSelect from "@/components/app/forms/TagsSelect";
import SelectProfessional from "@/components/app/forms/SelectProfessional";
import {
  parseISO,
  parse,
  format,
  addDays,
  addMonths,
  differenceInMinutes,
} from "date-fns";
import CustomerSearch from "@/components/customers/ui/CustomerSearch";
import SessionMixin from "@/mixins/Session/SessionMixin";
import { customerAlias } from "@/utils/customerAlias";
import AppBackBtn from "../../app/ui/AppBackBtn.vue";

export default {
  mixins: [SessionMixin],

  components: {
    TagsSelect,
    CustomerSearch,
    ColorSelect,
    SelectProfessional,
    OfficeSearch,
  },

  props: {
    hasOffices: {
      default: false,
    },
  },

  data() {
    return {
      formSessionType: "multiple",
      form: {},
      baseForm: {
        session_type: "session",
        title: null,
        has_repetitions: false,
        customer: {},
        office: {},
        value: null,
        frequency: "weekly",
        repetitions_count: 1,
        sessionDuration: 60,
        has_transaction: true,
        transaction_type: "sessionGroup",
        is_online_session: 0,
        video_call_link: null,
        status: null,
        sessions: [
          {
            start_at_date: null,
            start_at_time: null,
            start_at: null,
          },
        ],
        tags: [],
        color: "#d1c4e9",
      },

      dialog: false,
      valid: true,

      customerRules: [(v) => !!v || `Por favor, informe um ${customerAlias()}`],

      startAtDateRules: [(v) => !!v || `Por favor, informe uma data`],
      startAtTimeRules: [(v) => !!v || `Por favor, informe um horário`],
      repetitionsCountRules: [
        (v) => !!v || `Por favor, informe a quantidade de sessões`,
        (v) =>
          v > 1 || `Por favor, informe uma quantidade de sessões maior que 1`,
        (v) => v < 100 || `Por favor, informe um valor de 2 a 99`,
      ],
    };
  },

  created() {
    this.reset();
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },
    isClinic() {
      return this.$store.state.auth.company.type == "clinic";
    },

    user() {
      return this.$store.state.auth.user;
    },

    isProfessional() {
      return this.user.company_user.is_professional;
    },

    isSessionType() {
      return this.form.session_type == "session";
    },

    showSelectProfessional() {
      return this.isClinic && this.$acl.can("can_manage_all_calendar");
    },

    hasRepetitions() {
      return this.form.has_repetitions;
    },

    valueLabel() {
      if (!this.hasRepetitions) {
        return "Valor da sessão";
      }

      if (this.form.transaction_type == "sessionGroup") {
        return "Valor de todas as sessões";
      } else {
        return "Valor por sessão";
      }
    },

    dateLabel() {
      return this.hasRepetitions ? "Data da primeira sessão" : "Data";
    },
  },

  watch: {
    hasRepetitions() {
      if (this.hasRepetitions) {
        this.$nextTick(async () => {
          await this.$refs.form.resetValidation();
        });
      } else {
        this.form.repetitions_count = 1;
      }
    },

    formSessionType(val) {
      this.setType(val);
    },
  },

  methods: {
    async open() {
      await this.reset();

      this.dialog = true;
    },

    async openFormDate(date, time) {
      await this.reset();

      this.form.sessions[0].start_at_date = date;

      if (time) {
        this.form.sessions[0].start_at_time = this.roundTime(time);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.$nextTick(async () => {
        if (this.$refs["form"]) {
          await this.$refs.form.reset();
        }

        this.formSessionType = "single";
        this.form.session_type = "session";
        this.form.has_repetitions = false;
        this.form.sessionDuration = this.company.session_duration_default;
        this.form.has_transaction = true;
        this.form.color = "#d1c4e9";
        this.form.is_online_session = 0;

        if (this.isProfessional && !this.$acl.can("can_manage_all_calendar")) {
          this.setDefaultProfessional();
        }
      });
    },

    setType(type) {
      if (type == "single") {
        this.form.session_type = "session";
        this.form.has_repetitions = false;
      }

      if (type == "multiple") {
        this.form.session_type = "session";
        this.form.has_repetitions = true;
      }

      if (type == "custom_event") {
        this.form.session_type = "custom_event";
        this.form.has_repetitions = false;
      }
    },

    setDefaultProfessional() {
      this.form.professional = this.user;
    },

    calcSessions() {
      this.form.sessions.splice(1);

      for (let step = 1; step < this.form.repetitions_count; step++) {
        let date = this.form.sessions[0].start_at_date;

        let resultDate = format(
          this.addDaysFromFrequency(parseISO(date), this.form.frequency, step),
          "yyyy-MM-dd"
        );

        this.form.sessions.push({
          start_at_date: resultDate,
          start_at_time: this.form.sessions[0].start_at_time,
        });
      }
    },

    addDaysFromFrequency(date, frequency, quantity) {
      if (frequency == "weekly") {
        return addDays(date, 7 * quantity);
      }

      if (frequency == "biweekly") {
        return addDays(date, 14 * quantity);
      }

      if (frequency == "monthly") {
        return addMonths(date, 1 * quantity);
      }
    },

    setDuration() {
      let start = parse(
        this.form.sessions[0].start_at_time,
        "HH:mm",
        new Date()
      );
      let end = parse(this.form.sessions[0].end_at_time, "HH:mm", new Date());

      let diff = differenceInMinutes(end, start);

      this.form.sessionDuration = diff;
    },

    getParams() {
      let form = JSON.parse(JSON.stringify(this.form));

      form.sessions = form.sessions.map((row) => {
        return this.setSessionDates(row, this.form.sessionDuration);
      });

      return form;
    },

    setSessionValue() {
      if (this.form.customer.value_per_session) {
        this.form.value = this.form.customer.value_per_session;
      } else {
        this.form.value = null;
      }

      if (this.form.customer.video_call_link) {
        this.form.video_call_link = this.form.customer.video_call_link;
      } else {
        this.form.video_call_link = null;
      }
    },

    calcValue() {
      if (this.form.customer.value_per_session) {
        if (this.form.transaction_type == "sessionGroup") {
          this.form.value =
            this.form.customer.value_per_session * this.form.repetitions_count;
        }

        if (this.form.transaction_type == "session") {
          this.form.value = this.form.customer.value_per_session;
        }

        if (!this.form.has_repetitions) {
          this.form.value = this.form.customer.value_per_session;
        }
      }
    },

    handleSave() {
      if (!this.isSessionType) {
        this.form.customer = null;
        this.form.office = null;
        this.form.has_transaction = false;
        this.setDuration();
      }

      if (this.$refs.form.validate()) {
        this.calcSessions();

        this.store();
      }
    },

    async store() {
      this.$loading.start();

      await this.$http
        .store("sessions/session", this.getParams())
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$emit("store");
      this.$store.commit("onboarding/sessionStored");
      this.dialog = false;
      this.$loading.finish();
    },

    roundTime(timeStr) {
      let time = parse(timeStr, "HH:mm", new Date());

      let coeff = 1000 * 60 * 15;

      var roundedTime = new Date(Math.round(time.getTime() / coeff) * coeff);

      return format(roundedTime, "HH:mm");
    },
  },
};
</script>

<style lang="scss">
#chipSessionType {
  .v-slide-group__next--disabled,
  .v-slide-group__prev--disabled {
    display: none;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    display: none;
  }
}
</style>

